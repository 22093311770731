import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userInfo: null,
    },
    getters: {
        getUserInfo: (state) => state.userInfo
    },
    mutations: {
        setUserInfo(state, payload) {
            if (!payload) {
                payload = localStorage.getItem("userInfo");
                payload = JSON.parse(payload);
            }
            state.userInfo = payload;
            localStorage.setItem("userInfo", JSON.stringify(payload));
        },
        clearUserInfo(state) {
            state.userInfo = null;
            localStorage.removeItem("userInfo");
        },
    },
    actions: {
        authenticateUser: async (context, user) => {
            try {
                const {data} = await axios.post(
                    "https://lna.abc.co.zm/TicketApp/user/Login",
                    user
                );
                context.commit("setUserInfo", data);
                await context.dispatch(`refreshState`)
                router.push({name: "dashboard"});
                return true
            } catch (err) {
                console.log(err);
                return false
            }
        },
        refreshState: (context) => {
            context.commit("setUserInfo");
        },
        clearUserInfo: (context) => {
            context.commit("clearUserInfo");
            return router.push({name: "userLogin"});
        },
    },
    modules: {},
});
