<template>
  <div class="">
    <Header />
    <div class="container d-flex justify-content-center">
      <UserLoginForm />
    </div>
  </div>
</template>

<script>
import Header from "@/components/layout/utils/Header";
import UserLoginForm from "@/components/UserLoginForm";

export default {
  name: "userLogin",
  components: { Header, UserLoginForm },
};
</script>

<style lang="scss" scoped></style>
