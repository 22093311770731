<template>
  <nav class="navbar bg-white bg-gradient">
    <div class="container-fluid">
      <router-link class="navbar-brand" :to="{ name: 'dashboard' }">
        <img src="/Utils.png" alt="kazang logo" width="40px" />
        Lyt-Soft Utilities
      </router-link>
      <button
        class="navbar-toggler" type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
            {{ userInfo.Username }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div class="d-flex flex-column justify-content-between h-100">
            
              <ul class="navbar-nav justify-content-end flex-grow-1 pe-3 d-block">
                <li class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link class="nav-link" :to="{ name: 'dashboard' }">
                    Dashboard
                  </router-link>
                </li>

                <li class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link class="nav-link" :to="{ name: 'setups' }">
                    Setups
                  </router-link>
                </li>

                <li class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link class="nav-link" :to="{ name: 'databases' }">
                    Databases
                  </router-link>
                </li>

                <li class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link class="nav-link" :to="{ name: 'ChrilanSites' }">
                    Chrilan Sites
                  </router-link>
                </li>
              </ul>
            
          
              <button
                @click="logout"
                class="btn btn-outline-danger d-block"
              >
                Logout
              </button>
            
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";

import Swal from "sweetalert2";

export default {
  computed: {
    ...mapGetters(["getUserInfo"]),
    userInfo(){
      return this.getUserInfo;
    },
  },
  methods: {
    ...mapActions(["clearUserInfo"]),
    logout(){
      this.showConfirmAlert("Are you sure ? You are about to logout!")
    },
    showConfirmAlert(msg){
      return Swal.fire({
        title: msg,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, logout!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.clearUserInfo()
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped></style>