<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

import {mapActions} from "vuex";

export default {
  beforeMount() {
    this.refreshState();
  },
  methods: {
    ...mapActions(["refreshState"]),
  },
};
</script>

<style></style>
