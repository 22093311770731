<template>
  <div class="kazang-logo p-1">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="500.000000pt" height="500.000000pt" viewBox="0 0 500.000000 500.000000"
         preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
         fill="#000000" stroke="none">
        <path d="M2410 4390 c-350 -44 -671 -168 -956 -371 -91 -65 -253 -217 -333
-313 -75 -90 -149 -189 -153 -205 -2 -5 139 -104 312 -220 259 -172 317 -207
326 -195 12 15 29 20 20 6 -2 -4 6 -8 20 -8 15 0 20 4 15 9 -12 12 14 67 30
67 6 0 7 6 2 13 -4 6 7 1 24 -13 23 -18 28 -28 20 -35 -7 -5 -18 -22 -26 -37
-8 -15 -19 -25 -24 -21 -6 3 -7 1 -4 -5 4 -5 -1 -25 -10 -44 -10 -18 -18 -34
-19 -36 0 -1 -32 12 -69 29 -57 25 -227 133 -405 256 -141 98 -241 163 -249
163 -15 0 -126 -227 -164 -336 -59 -169 -107 -445 -107 -617 0 -184 58 -512
117 -662 31 -79 58 -134 161 -328 43 -82 227 -300 342 -408 157 -147 379 -293
550 -362 420 -169 783 -208 1185 -125 173 35 406 118 535 190 205 114 431 304
574 482 100 123 129 166 188 276 121 226 195 497 219 796 39 478 -116 974
-421 1349 -298 367 -720 610 -1200 691 -106 18 -399 26 -500 14z m240 -143 c0
-16 -20 -37 -36 -37 -18 0 -28 39 -14 55 14 17 50 3 50 -18z m-480 10 c0 -19
43 -37 62 -26 22 12 55 -25 40 -44 -16 -19 -3 -57 19 -57 14 0 19 -7 19 -30 0
-18 -5 -30 -13 -30 -20 0 -38 -45 -24 -59 16 -16 -21 -55 -39 -40 -16 13 -54
-5 -54 -26 0 -10 -10 -15 -30 -15 -18 0 -30 5 -30 13 0 19 -52 36 -73 23 -13
-8 -21 -7 -34 7 -15 14 -16 21 -5 37 15 24 0 60 -23 60 -10 0 -15 10 -15 30 0
18 5 30 13 30 20 0 38 45 24 59 -8 8 -6 17 11 33 16 16 25 19 33 11 14 -14 69
5 69 24 0 7 11 13 25 13 14 0 25 -6 25 -13z m248 -18 c4 -30 -22 -46 -44 -28
-17 14 -18 31 -2 47 19 19 43 9 46 -19z m117 21 c11 -17 -5 -50 -24 -50 -24 0
-43 23 -35 44 7 18 49 22 59 6z m220 -20 c0 -18 -6 -26 -23 -28 -13 -2 -25 3
-28 12 -10 26 4 48 28 44 17 -2 23 -10 23 -28z m109 7 c8 -12 7 -21 -5 -32
-21 -21 -49 -9 -49 20 0 36 34 44 54 12z m104 -20 c4 -20 -25 -34 -40 -19 -15
15 -1 44 19 40 10 -2 19 -11 21 -21z m82 -27 c0 -11 -7 -20 -15 -20 -8 0 -15
9 -15 20 0 11 7 20 15 20 8 0 15 -9 15 -20z m90 -24 c0 -20 -19 -31 -32 -18
-6 6 -7 15 -4 21 10 16 36 13 36 -3z m83 -31 c1 -5 -6 -11 -15 -13 -11 -2 -18
3 -18 13 0 17 30 18 33 0z m-692 -51 c9 -11 10 -20 3 -32 -15 -23 -29 -25 -48
-7 -12 12 -13 21 -6 36 13 23 33 25 51 3z m107 7 c21 -13 10 -46 -16 -49 -27
-4 -40 22 -22 43 14 17 20 18 38 6z m126 -16 c9 -23 -22 -47 -45 -35 -24 13
-25 36 -2 48 20 12 39 6 47 -13z m703 -5 c62 -36 80 -77 71 -162 -2 -24 -89
-25 -112 -2 -15 14 -16 14 -16 0 0 -19 -13 -20 -41 -6 -21 12 -25 50 -7 68 16
16 48 15 48 -1 0 -8 15 -14 41 -15 38 -3 40 -2 34 20 -18 56 -69 85 -121 68
-98 -33 -98 -173 -1 -198 27 -7 33 -14 35 -40 3 -31 2 -32 -25 -26 -88 21
-143 84 -143 164 0 59 26 106 74 135 55 33 99 32 163 -5z m-1049 -8 c4 -28
-24 -40 -45 -19 -14 13 -14 19 -3 32 18 22 44 15 48 -13z m442 13 c17 -20 5
-45 -20 -45 -11 0 -23 7 -26 15 -6 15 11 45 26 45 4 0 13 -7 20 -15z m-1146
-45 l47 -51 46 51 c46 52 63 59 81 37 9 -10 -5 -29 -59 -83 l-70 -69 -66 68
c-54 56 -63 70 -53 82 18 22 23 20 74 -35z m1251 10 c0 -8 -10 -16 -22 -18
-18 -3 -23 2 -23 18 0 16 5 21 23 18 12 -2 22 -10 22 -18z m90 -10 c8 -13 -14
-42 -26 -34 -5 3 -9 14 -9 25 0 20 24 26 35 9z m90 -40 c0 -8 -8 -16 -17 -18
-13 -2 -18 3 -18 18 0 15 5 20 18 18 9 -2 17 -10 17 -18z m-1517 -60 c93 -57
95 -186 2 -248 -29 -20 -44 -23 -88 -20 -45 4 -59 10 -89 41 -31 30 -37 44
-40 89 -3 41 1 60 16 86 28 43 77 72 126 72 22 0 54 -9 73 -20z m974 -27 c4
-110 16 -97 -97 -105 -312 -21 -584 -139 -811 -352 -90 -84 -92 -85 -110 -65
-10 11 -33 29 -51 40 -18 11 -33 24 -33 28 0 4 31 39 69 78 80 81 229 190 349
257 95 52 321 128 432 146 41 6 82 13 90 15 8 1 48 3 88 4 l72 1 2 -47z m-684
21 c28 -19 28 -55 1 -79 -17 -16 -24 -17 -51 -7 -17 6 -34 9 -38 7 -16 -10 10
70 26 82 23 17 35 16 62 -3z m1845 -19 c21 -10 50 -34 65 -53 24 -31 27 -44
27 -113 0 -75 -1 -79 -35 -115 -62 -65 -138 -81 -215 -45 -144 67 -146 258 -2
331 49 24 102 23 160 -5z m-1067 -76 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11
33 5 0 12 -5 16 -11z m62 -12 c2 -12 -3 -17 -17 -17 -12 0 -21 6 -21 13 0 31
32 34 38 4z m52 -13 c0 -8 -7 -14 -15 -14 -15 0 -21 21 -9 33 10 9 24 -2 24
-19z m60 -10 c0 -9 -7 -14 -17 -12 -25 5 -28 28 -4 28 12 0 21 -6 21 -16z
m300 -49 c-16 -37 -17 -45 -5 -45 18 0 20 -26 4 -36 -6 -3 -15 -1 -19 7 -6 9
-14 2 -26 -24 -19 -39 -22 -41 -41 -29 -8 5 -2 29 23 87 19 44 39 82 44 83 6
2 7 8 4 14 -4 6 2 6 14 -1 21 -11 21 -11 2 -56z m-240 40 c0 -8 -6 -15 -14
-15 -17 0 -28 14 -19 24 12 12 33 6 33 -9z m68 -17 c-7 -19 -38 -22 -38 -4 0
10 9 16 21 16 12 0 19 -5 17 -12z m38 -25 c-3 -8 -6 -17 -6 -19 0 -3 -4 -2
-10 1 -17 11 -12 45 6 38 8 -3 13 -12 10 -20z m220 3 c3 -8 -2 -29 -12 -47
-11 -23 -13 -34 -5 -37 20 -7 13 -35 -8 -29 -14 3 -23 -4 -35 -29 -17 -36 -33
-43 -42 -20 -6 17 72 176 86 176 6 0 13 -6 16 -14z m-156 -20 c0 -8 -4 -17 -8
-20 -13 -8 -35 11 -28 23 10 16 36 14 36 -3z m235 -26 c4 -6 -2 -26 -14 -45
-12 -22 -16 -35 -9 -37 7 -3 9 -12 5 -21 -3 -10 -13 -14 -21 -11 -9 4 -20 -5
-31 -25 -8 -17 -20 -31 -26 -31 -25 0 -18 36 22 105 40 71 60 88 74 65z
m-2005 -15 c26 -32 -13 -81 -47 -59 -35 22 -23 74 17 74 10 0 23 -7 30 -15z
m1270 -30 c0 -9 9 -15 21 -15 19 0 21 -4 15 -31 -3 -17 -6 -42 -6 -55 0 -15
-6 -24 -15 -24 -8 0 -15 7 -15 16 0 15 -11 16 -97 11 -132 -8 -262 -45 -394
-113 -90 -46 -125 -71 -209 -151 l-100 -95 -34 28 c-53 44 -52 52 6 111 106
108 338 247 478 287 149 42 350 60 350 31z m809 -4 c11 -7 10 -14 -5 -37 -11
-15 -19 -40 -19 -54 0 -18 -5 -24 -17 -22 -9 1 -27 -11 -39 -27 -17 -23 -26
-28 -36 -20 -15 13 -8 29 51 111 44 62 44 62 65 49z m-674 -63 c-9 -66 -10
-68 -42 -68 -24 0 -25 2 -19 38 3 20 6 47 6 60 0 18 6 22 31 22 l31 0 -7 -52z
m1343 -60 c-6 -17 -12 -13 -61 39 -31 33 -46 57 -41 64 5 9 23 -4 56 -40 27
-30 47 -58 46 -63z m-1262 96 c20 -5 20 -7 6 -65 -14 -57 -24 -67 -50 -51 -9
7 -10 20 -1 65 7 31 15 57 18 57 3 0 15 -3 27 -6z m106 -33 c9 -9 -32 -111
-45 -111 -35 1 -39 16 -21 74 15 49 20 55 38 50 12 -3 24 -9 28 -13z m541 -40
c-14 -18 -20 -36 -16 -51 5 -18 2 -21 -13 -18 -11 2 -29 -8 -44 -25 -22 -25
-28 -27 -38 -15 -10 12 -1 29 45 92 51 68 58 75 72 61 13 -13 13 -18 -6 -44z
m-1998 -1 c0 -33 -2 -35 -35 -35 -29 0 -36 4 -38 23 -6 40 5 54 40 50 30 -3
33 -6 33 -38z m1553 5 c1 -1 -9 -27 -23 -58 -23 -54 -26 -56 -49 -46 l-25 12
20 50 c11 28 23 55 25 59 4 6 36 -4 52 -17z m-1776 -12 c20 -18 24 -73 6 -91
-17 -17 -74 -15 -92 4 -9 8 -16 27 -16 41 0 55 62 83 102 46z m2718 -39 l25
-27 -25 13 c-32 16 -60 41 -60 52 0 13 31 -7 60 -38z m-860 4 c0 -13 -38 -86
-49 -92 -5 -3 -17 -3 -26 0 -18 7 -16 25 10 87 14 34 17 36 40 25 14 -6 25
-15 25 -20z m439 13 c15 -10 11 -16 -77 -111 -28 -30 -34 -33 -47 -20 -13 14
-8 23 41 78 59 66 61 67 83 53z m-355 -59 c5 -9 -51 -102 -62 -102 -18 1 -43
30 -36 42 49 82 54 88 74 78 10 -5 21 -14 24 -18z m938 6 c7 -20 -287 -245
-297 -228 -10 16 0 25 130 125 68 53 127 100 130 105 9 14 33 12 37 -2z m-418
-27 c-2 -3 -12 3 -22 13 -16 17 -16 18 5 5 12 -7 20 -15 17 -18z m-919 -23
c52 -11 173 -55 189 -69 5 -4 -1 -28 -13 -54 -17 -37 -26 -45 -39 -40 -184 78
-377 93 -556 43 -99 -27 -221 -87 -300 -148 l-67 -52 -34 39 c-31 36 -32 39
-17 57 27 29 202 143 264 170 72 32 164 56 253 66 80 10 247 3 320 -12z
m-1405 -2 c28 -35 -11 -93 -47 -70 -36 22 -23 84 17 84 10 0 23 -7 30 -14z
m1873 -1 c18 -13 17 -16 -16 -64 l-34 -52 -23 19 -23 19 32 46 c35 51 38 53
64 32z m526 -41 c17 -16 20 -24 10 -29 -7 -5 -10 -4 -6 2 3 5 -5 18 -19 28
-14 10 -30 28 -35 39 -10 21 -10 21 8 1 10 -11 29 -30 42 -41z m-204 4 l38
-41 -43 -40 -42 -40 -41 42 -41 41 39 40 c21 22 42 40 46 40 4 0 24 -19 44
-42z m593 8 c-71 -62 -265 -202 -294 -213 -9 -3 -14 2 -14 13 0 12 19 30 51
49 28 17 94 65 147 107 79 63 98 75 105 62 5 -9 7 -17 5 -18z m37 -56 c11 -18
-14 -39 -152 -131 -141 -95 -153 -101 -160 -82 -3 7 37 40 94 77 54 36 121 83
148 105 54 43 61 46 70 31z m-2687 -12 c7 -7 12 -21 12 -33 0 -44 -44 -59 -75
-25 -17 18 -17 21 -1 45 18 27 45 32 64 13z m2130 -51 c5 -23 -75 -74 -91 -58
-9 9 -3 19 28 46 42 36 58 39 63 12z m-245 -47 c73 -78 162 -213 199 -300 29
-71 59 -176 76 -269 10 -57 6 -61 -59 -61 -33 0 -39 3 -40 23 l-2 22 -4 -22
c-4 -21 -11 -25 -63 -37 -24 -5 -26 -2 -48 92 -36 148 -89 248 -209 390 -29
34 -53 66 -53 70 0 11 130 152 140 152 4 0 33 -27 63 -60z m845 25 c-3 -12
-163 -123 -265 -183 -39 -23 -53 -27 -57 -17 -3 9 49 49 152 118 147 99 177
113 170 82z m-3253 6 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15
-9z m186 -80 c39 -40 40 -93 3 -128 -55 -51 -91 -50 -152 3 -41 36 -44 84 -7
108 14 9 25 23 25 30 0 20 50 35 78 24 13 -4 37 -21 53 -37z m2514 38 c7 -10
1 -16 -58 -60 -25 -19 -28 -19 -34 -3 -4 12 4 25 28 45 36 30 54 35 64 18z
m-900 -24 c94 -20 195 -70 286 -143 145 -115 240 -266 273 -437 3 -16 -12 7
-34 53 -109 221 -326 345 -552 313 -105 -15 -157 -33 -300 -104 -71 -35 -128
-60 -128 -56 0 18 101 116 154 151 123 79 278 99 491 63 61 -10 -54 36 -128
51 -115 25 -240 3 -369 -62 -125 -63 -254 -258 -291 -439 -14 -68 -14 -69 -22
-35 -12 54 -8 152 10 222 22 88 67 160 155 247 79 78 78 80 -11 20 -92 -62
-155 -164 -184 -299 -11 -52 -12 -54 -19 -24 -11 50 11 153 50 232 28 58 49
84 100 126 74 61 154 104 227 122 71 17 208 17 292 -1z m-505 -22 c-50 -33
-149 -142 -174 -192 -64 -125 -70 -244 -20 -381 25 -70 115 -248 159 -314 l19
-29 -24 13 c-14 7 -56 45 -93 84 -168 174 -221 417 -131 599 37 77 154 188
227 216 63 24 69 25 37 4z m-630 -30 c-10 -21 -15 -42 -12 -48 3 -5 -2 -4 -12
4 -15 13 -16 15 -2 23 9 5 16 15 16 21 0 11 19 37 26 37 1 0 -6 -17 -16 -37z
m2130 -104 c0 -13 -87 -66 -95 -57 -1 2 -16 30 -33 63 l-29 59 45 33 46 33 33
-58 c18 -31 33 -64 33 -73z m-1840 86 l34 -35 -22 -27 c-13 -16 -35 -45 -50
-65 -15 -21 -31 -37 -35 -38 -5 0 -26 12 -46 27 l-38 27 26 36 c43 59 86 110
92 110 3 0 20 -16 39 -35z m-1017 -12 c4 -3 2 -12 -4 -20 -9 -10 -15 -10 -25
-2 -8 6 -11 15 -8 20 6 11 28 12 37 2z m455 -100 c45 -48 43 -80 -9 -126 -22
-21 -47 -37 -54 -37 -50 0 -125 64 -125 107 0 13 11 34 25 47 14 13 25 28 25
33 0 6 15 15 33 20 39 12 60 3 105 -44z m-403 -15 c52 -46 42 -144 -18 -175
-43 -22 -106 -12 -134 22 -35 40 -32 108 6 146 38 38 106 41 146 7z m3035 7
c0 -2 -13 -11 -30 -20 -16 -9 -30 -11 -30 -6 0 7 22 19 58 30 1 1 2 -1 2 -4z
m-2164 -75 c-1 -3 -17 -38 -36 -77 -19 -40 -38 -73 -42 -73 -5 0 -24 7 -43 15
l-35 14 20 51 c11 28 29 69 40 90 l20 40 40 -28 c21 -15 38 -29 36 -32z m1974
21 c0 -3 -21 -15 -47 -26 -26 -11 -50 -21 -54 -23 -4 -2 -9 6 -12 18 -4 17 4
25 41 45 43 22 48 22 60 7 6 -9 12 -18 12 -21z m196 -16 c-11 -8 -27 -15 -35
-15 -13 1 -12 4 3 15 11 8 27 15 35 15 13 -1 12 -4 -3 -15z m-2434 -15 c-5
-13 -3 -13 10 -2 19 15 31 3 17 -18 -6 -11 -9 -12 -9 -2 0 7 -4 11 -10 7 -13
-8 -58 5 -63 18 -3 8 7 12 28 12 25 0 31 -4 27 -15z m1366 -9 c73 -22 190 -98
230 -149 39 -49 92 -155 108 -217 29 -107 10 -285 -39 -373 -12 -20 -13 -12
-9 62 6 119 -25 253 -77 333 -72 110 -205 213 -306 237 -16 4 -57 20 -90 36
-33 16 -77 32 -98 35 -49 10 -55 21 -15 34 55 17 70 19 155 20 54 1 101 -5
141 -18z m-1828 -11 c0 -12 7 -20 18 -20 16 -1 16 -1 0 -11 -10 -5 -18 -17
-18 -24 0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 -7 15 -15 15 -8 0 -15 5
-15 10 0 6 7 10 15 10 8 0 15 9 15 20 0 11 5 20 10 20 6 0 10 -9 10 -20z
m2970 0 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m-250 -9 c0 -5 2 -16 5 -23 4 -10 -11 -20 -46 -32 -43 -16 -53 -17 -60
-5 -14 23 -11 27 34 48 47 22 67 25 67 12z m190 -16 c-30 -17 -40 -18 -40 -6
0 10 22 18 50 19 8 1 4 -5 -10 -13z m160 -15 c0 -5 -5 -10 -11 -10 -5 0 -7 5
-4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-2620 -16 c0 -8 4 -12 10 -9 6 3 7 -1
4 -9 -7 -19 -24 -11 -24 12 -1 15 -2 15 -11 -2 -9 -15 -13 -16 -20 -5 -14 23
-11 -7 5 -45 11 -26 11 -33 1 -33 -7 0 -11 7 -8 15 5 12 3 12 -16 1 -11 -8
-21 -22 -21 -31 0 -10 -7 -18 -15 -18 -9 0 -15 9 -14 23 0 21 1 21 9 2 6 -14
9 -15 9 -5 1 9 10 23 21 33 22 18 27 39 9 35 -6 -2 -14 3 -17 11 -3 8 0 11 7
7 6 -4 11 -2 11 4 0 6 10 15 23 20 32 13 37 12 37 -6z m2540 -4 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-2218 -96 c-10
-196 72 -365 235 -481 73 -52 240 -116 388 -149 l30 -7 -30 -13 c-110 -47
-276 -39 -400 21 -200 96 -324 350 -275 562 10 41 49 153 54 153 1 0 0 -39 -2
-86z m2153 76 c-27 -12 -43 -12 -25 0 8 5 22 9 30 9 10 0 8 -3 -5 -9z m-1328
-33 c28 -15 54 -31 58 -37 3 -5 -10 -16 -30 -24 -40 -17 -43 -26 -17 -49 36
-33 64 -105 64 -165 0 -75 -12 -103 -70 -161 -105 -105 -258 -90 -354 34 l-42
53 -29 -15 c-44 -22 -53 0 -47 122 5 106 111 240 212 266 18 4 71 7 118 5 69
-2 95 -8 137 -29z m-1153 8 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4
15 2 0 6 -7 10 -15z m2312 -43 c6 -28 15 -80 19 -116 l7 -66 -55 0 -55 0 -6
54 c-4 29 -13 76 -21 105 l-13 51 46 14 c26 8 51 13 57 12 5 -1 15 -26 21 -54z
m190 19 c-8 -12 -46 -21 -46 -11 0 5 8 11 18 13 29 8 33 7 28 -2z m-2734 -16
c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z
m-272 -33 c0 -35 3 -39 36 -51 33 -12 38 -11 59 9 26 24 44 19 80 -20 l20 -22
-28 -22 -27 -22 16 -42 c16 -39 20 -42 54 -42 l37 0 -1 -47 c-1 -48 -1 -48
-37 -51 -30 -3 -39 -9 -53 -39 -16 -34 -16 -36 4 -57 27 -29 26 -37 -10 -71
l-30 -29 -26 25 c-23 23 -29 25 -60 15 -31 -10 -34 -14 -34 -48 l0 -38 -45 0
-45 0 0 38 c0 34 -3 38 -35 48 -28 9 -37 8 -47 -3 -27 -33 -44 -33 -77 1 l-32
33 25 26 c24 26 24 27 8 67 -16 36 -20 40 -54 40 l-38 0 0 45 0 45 38 0 c34 0
38 4 55 42 l17 42 -27 22 -28 22 34 36 35 36 27 -26 c25 -24 29 -25 63 -13 33
12 36 16 36 51 l0 38 45 0 45 0 0 -38z m480 -62 c0 -63 -3 -80 -14 -80 -8 0
-17 -4 -20 -9 -4 -5 3 -7 15 -4 17 5 20 2 17 -13 -2 -15 -14 -20 -57 -22 -52
-3 -53 -2 -46 20 4 13 8 43 9 68 1 25 8 54 15 65 13 22 13 24 -5 -47 -4 -18
-3 -20 5 -8 6 8 11 21 11 29 0 7 11 26 24 42 24 28 25 29 29 6 4 -19 4 -18 6
6 0 15 3 27 6 27 3 0 5 -36 5 -80z m1487 -62 c124 -161 164 -369 104 -530 -26
-68 -95 -148 -168 -195 -96 -61 -107 -56 -33 13 76 72 118 144 145 249 28 112
14 254 -36 343 -11 20 -17 28 -14 17 29 -91 29 -204 -1 -318 -27 -103 -62
-163 -139 -235 -123 -115 -220 -153 -385 -154 -159 0 -282 43 -415 145 -129
99 -234 249 -269 383 -21 80 -20 91 3 33 89 -227 331 -373 561 -338 92 14 148
33 292 104 73 35 123 55 118 46 -15 -28 -124 -128 -168 -155 -63 -39 -179 -66
-281 -66 -79 0 -111 5 -181 24 -15 4 -9 -2 15 -15 59 -33 131 -49 215 -49 90
0 153 16 238 59 84 42 141 96 196 185 42 67 105 207 107 238 0 7 5 0 9 -16 12
-39 -4 -159 -30 -228 -22 -59 -61 -120 -114 -177 -21 -23 -25 -30 -11 -23 30
15 120 113 140 152 81 157 81 383 0 558 l-34 73 46 -33 c25 -18 65 -59 90 -90z
m1028 112 c-3 -5 -14 -10 -23 -9 -14 0 -13 2 3 9 27 11 27 11 20 0z m345 -130
c25 -47 6 -102 -40 -118 -40 -14 -84 1 -99 34 -14 31 -14 47 1 79 21 46 114
50 138 5z m-292 -12 c28 -28 1 -75 -38 -63 -14 4 -20 15 -20 34 0 15 3 31 7
34 10 11 38 8 51 -5z m-424 -170 c-13 -119 -67 -344 -88 -365 -4 -4 -106 36
-106 42 0 1 11 32 24 67 29 76 56 201 56 256 0 21 3 42 7 45 3 4 30 7 59 7
l53 0 -5 -52z m212 -10 c-3 -34 -7 -63 -9 -64 -1 -1 -24 2 -50 8 l-47 10 0 47
c0 26 3 51 7 54 3 4 29 7 56 7 l50 0 -7 -62z m184 18 c0 -10 14 -16 43 -18 38
-3 42 -5 40 -28 -1 -14 -5 -60 -8 -104 l-7 -79 -66 7 c-37 4 -67 8 -69 9 -1 1
3 46 10 100 8 66 15 97 24 97 7 0 13 7 13 15 0 8 5 15 10 15 6 0 10 -6 10 -14z
m-2610 -51 c0 -17 -2 -17 -10 -5 -5 8 -10 24 -10 35 0 17 2 17 10 5 5 -8 10
-24 10 -35z m30 27 c0 -10 5 -23 12 -30 9 -9 8 -17 -2 -37 -7 -14 -11 -27 -8
-30 3 -3 13 12 22 35 16 41 32 56 21 19 -5 -15 -4 -19 5 -14 7 4 10 2 9 -6 -2
-13 2 -28 21 -74 4 -11 20 -51 35 -90 52 -137 180 -348 254 -419 22 -22 37
-44 34 -49 -4 -6 -1 -7 7 -2 12 8 70 -47 70 -66 0 -5 5 -9 11 -9 5 0 7 6 3 13
-4 6 -3 8 1 4 18 -16 14 -39 -5 -34 -20 5 -30 -9 -22 -30 6 -17 22 -16 22 1 0
7 9 16 20 19 16 4 18 2 11 -11 -4 -10 -6 -22 -4 -28 6 -18 -40 -26 -53 -9 -6
8 -16 15 -22 15 -7 0 -9 7 -5 18 6 15 5 15 -10 3 -11 -9 -17 -10 -17 -2 0 6
-5 11 -11 11 -32 0 -242 203 -292 281 -72 113 -157 329 -143 365 6 16 9 16 17
4 8 -11 9 -10 4 8 -4 14 -2 22 6 22 7 0 9 3 5 7 -4 5 -14 -1 -22 -11 -7 -11
-17 -17 -20 -13 -4 4 -2 12 4 20 7 8 7 18 2 27 -6 8 -9 22 -9 30 0 10 3 8 9
-5 7 -17 9 -17 9 -2 1 9 5 17 10 17 4 0 6 -12 4 -27 l-5 -28 16 29 c15 26 15
31 1 52 -17 27 -19 44 -5 44 6 0 10 -8 10 -18z m2910 -27 l0 -35 -65 0 -65 0
0 35 0 35 65 0 65 0 0 -35z m-2860 21 c0 -9 -30 -14 -35 -6 -4 6 3 10 14 10
12 0 21 -2 21 -4z m2856 -118 c1 -2 0 -16 -3 -31 -5 -23 -10 -28 -27 -23 -11
3 -40 6 -63 6 -41 0 -43 1 -43 31 l0 32 66 -6 c37 -4 68 -8 70 -9z m-3098
-100 c-3 -25 -34 -36 -50 -20 -17 17 -1 42 27 42 22 0 26 -4 23 -22z m3081
-14 c2 -2 0 -15 -3 -29 -7 -26 -8 -27 -69 -20 -34 4 -63 8 -65 10 -2 1 0 15 4
30 7 26 7 27 68 20 33 -4 63 -9 65 -11z m-3549 -49 c0 -73 -31 -74 -35 -1 -2
41 4 56 21 56 10 0 14 -15 14 -55z m-46 -9 c-5 -13 -9 -14 -20 -5 -11 9 -12
15 -3 26 15 17 32 2 23 -21z m3286 23 c27 -6 50 -14 50 -17 0 -4 -7 -40 -17
-79 -19 -81 -18 -81 -100 -57 -48 14 -54 19 -49 37 3 12 11 46 17 75 14 58 16
59 99 41z m-2917 -62 c57 -44 81 -84 85 -146 4 -70 -17 -113 -76 -158 -38 -29
-51 -33 -105 -33 -75 0 -119 20 -161 73 -25 31 -31 49 -34 100 -4 57 -1 66 30
112 44 66 85 87 164 83 48 -3 69 -10 97 -31z m652 23 c3 -6 -1 -7 -9 -4 -18 7
-21 14 -7 14 6 0 13 -4 16 -10z m-437 -23 c4 -20 -25 -34 -40 -19 -15 15 -1
44 19 40 10 -2 19 -11 21 -21z m473 -14 c13 -16 12 -17 -3 -4 -10 7 -18 15
-18 17 0 8 8 3 21 -13z m2477 1 c44 -7 48 -13 33 -43 -9 -16 -17 -17 -70 -8
-61 10 -68 17 -55 52 5 11 14 15 28 11 11 -3 40 -8 64 -12z m-572 -68 c64 -22
116 -57 106 -70 -5 -6 -15 -27 -21 -46 -33 -102 -241 -375 -365 -478 -127
-106 -249 -187 -269 -179 -10 4 -87 146 -87 161 0 2 21 17 48 32 69 40 198
140 265 204 l58 56 38 -33 39 -33 38 46 38 47 -25 18 c-71 52 -67 42 -33 91
29 39 49 76 92 166 18 36 24 38 78 18z m-507 -22 c17 -14 31 -30 31 -36 0 -18
-89 -109 -155 -160 -87 -66 -191 -120 -295 -154 -80 -26 -95 -28 -260 -28
-162 0 -182 2 -265 28 -49 15 -91 29 -94 31 -6 6 29 95 38 95 3 0 51 -11 105
-25 287 -73 590 9 790 212 34 33 64 62 68 62 3 1 20 -11 37 -25z m-1839 -9 c0
-8 -6 -15 -14 -15 -17 0 -28 14 -19 24 12 12 33 6 33 -9z m2878 -6 c50 -12 53
-15 47 -39 -4 -14 -8 -27 -11 -29 -4 -4 -114 25 -121 32 -9 9 10 58 21 54 6
-2 35 -10 64 -18z m-290 -44 c28 -9 54 -20 57 -25 8 -13 -55 -160 -69 -159 -6
0 -36 13 -67 27 l-56 27 34 73 c20 39 38 72 42 72 4 0 30 -7 59 -15z m-2493
-59 c59 -25 82 -104 50 -166 -9 -17 -34 -40 -58 -51 -23 -12 -99 -80 -169
-151 -114 -116 -153 -144 -153 -110 0 6 53 65 118 132 64 67 117 124 117 127
0 3 -13 17 -29 32 -57 54 -49 133 18 178 39 27 59 28 106 9z m2742 -12 c26 -9
49 -17 51 -19 1 -1 -2 -14 -7 -28 l-10 -26 -61 20 c-61 21 -65 25 -54 53 8 20
21 20 81 0z m-957 -129 c32 -33 33 -35 16 -53 -50 -56 -323 -222 -364 -222
-24 0 -20 -23 23 -156 25 -76 45 -141 45 -145 0 -11 -58 -32 -73 -26 -7 3 -19
23 -25 46 l-13 41 -49 -19 c-28 -11 -58 -18 -68 -16 -9 1 -12 0 -5 -2 7 -2 9
-11 6 -19 -4 -11 4 -14 38 -12 l44 1 -39 4 -39 4 28 15 c15 8 35 14 44 13 12
0 12 -2 -4 -9 -19 -8 -19 -9 3 -9 35 -1 21 -23 -24 -37 l-39 -12 43 5 c28 4
42 2 42 -6 0 -7 -9 -9 -26 -5 -21 5 -25 4 -19 -6 5 -9 4 -11 -4 -6 -6 4 -27 2
-47 -4 -43 -14 -184 -21 -184 -9 0 5 17 9 38 10 32 1 33 2 12 8 -15 4 -26 15
-28 28 -2 12 -10 27 -18 33 -8 8 -14 31 -14 60 l0 48 68 7 c66 7 202 31 208
38 5 5 -17 81 -26 90 -4 5 -25 4 -46 -1 -22 -6 -76 -13 -121 -16 l-83 -6 0 53
0 54 88 12 c201 25 445 132 541 235 14 14 27 26 31 26 3 0 21 -16 40 -35z
m918 10 l53 -24 -16 -31 c-8 -16 -18 -30 -21 -30 -2 0 -31 14 -64 30 -57 29
-59 31 -48 55 14 31 29 31 96 0z m-320 -37 c31 -17 58 -33 60 -34 9 -6 -94
-154 -106 -154 -3 0 -29 18 -59 40 l-53 41 47 69 c27 39 49 70 51 70 1 0 28
-14 60 -32z m-2624 6 c22 -8 20 -51 -2 -63 -37 -20 -70 27 -40 57 14 14 20 14
42 6z m428 -51 c24 -25 42 -50 40 -55 -1 -5 2 -7 8 -3 13 8 42 -14 34 -26 -3
-5 2 -9 11 -9 8 0 18 -7 21 -15 4 -8 12 -15 20 -15 8 0 14 -5 14 -11 0 -6 -7
-9 -15 -5 -13 5 -14 2 -4 -16 9 -16 13 -18 20 -7 5 8 9 9 9 3 0 -15 -157 -188
-284 -314 -71 -70 -109 -101 -116 -94 -7 7 9 30 48 70 31 33 110 117 175 187
85 90 123 124 135 120 13 -4 12 -1 -3 11 l-20 18 20 -7 c16 -5 17 -4 8 6 -10
10 -15 9 -27 -1 -9 -7 -13 -17 -9 -24 3 -6 2 -8 -2 -3 -14 12 -17 49 -3 41 7
-4 8 -3 4 4 -4 6 -13 9 -21 6 -8 -4 -12 -11 -8 -17 4 -6 1 -7 -6 -3 -7 5 -10
15 -7 23 3 8 2 12 -3 9 -12 -8 -33 19 -23 29 4 5 1 5 -6 1 -7 -4 -26 2 -43 15
-31 22 -82 23 -149 3 -22 -7 -24 -6 -11 9 7 9 21 17 30 17 9 0 39 23 66 50 27
28 50 50 52 50 1 0 22 -21 45 -47z m-607 7 c-3 -5 -13 -10 -21 -10 -20 0 -18
-28 4 -33 9 -3 -1 -5 -23 -6 -32 0 -36 2 -22 10 23 13 22 29 -3 29 -11 0 -20
5 -20 10 0 6 21 10 46 10 27 0 43 -4 39 -10z m956 -57 c-12 -20 -14 -14 -5 12
4 9 9 14 11 11 3 -2 0 -13 -6 -23z m-833 5 c16 -16 15 -23 -3 -41 -29 -30 -80
8 -54 39 14 17 41 18 57 2z m2900 -18 c66 -35 66 -34 48 -69 -9 -17 -19 -31
-23 -31 -5 0 -78 43 -111 66 -6 4 30 54 40 54 5 0 25 -9 46 -20z m-1953 -12
c16 -7 24 -17 20 -26 -4 -11 -2 -12 7 -4 9 7 37 2 98 -18 47 -16 93 -29 102
-29 14 -1 15 -6 8 -31 -10 -33 -10 -36 -10 -50 0 -5 -11 -10 -24 -10 -31 0
-155 38 -183 56 -13 8 -23 12 -23 9 0 -3 -18 4 -41 15 -28 15 -39 26 -35 36 3
9 6 18 6 20 0 3 5 2 10 -1 6 -3 10 2 10 13 0 34 14 39 55 20z m-682 -37 c-19
-14 -47 -67 -45 -86 2 -17 1 -17 -7 -5 -14 20 -14 26 4 44 9 8 15 18 14 21 -2
12 36 55 40 44 2 -6 -1 -14 -6 -18z m-335 -25 c4 -35 -9 -50 -36 -42 -26 9
-35 32 -21 57 16 31 53 21 57 -15z m502 9 c16 -9 36 -29 45 -45 30 -59 24 -72
-121 -225 -75 -80 -139 -145 -143 -145 -26 0 -12 23 59 96 44 45 80 87 80 93
0 6 -10 15 -22 20 -52 20 -82 80 -67 135 20 73 100 107 169 71z m2102 -41 l58
-46 -67 -74 c-37 -41 -69 -73 -73 -72 -3 2 -27 26 -53 54 l-47 51 21 19 c11
10 36 40 56 66 19 27 38 48 41 48 4 0 33 -21 64 -46z m276 -44 c47 -34 49 -40
26 -75 l-16 -24 -51 36 c-53 36 -57 45 -30 76 21 22 23 22 71 -13z m-2101 -20
c-19 -27 -33 -9 -17 21 8 14 15 18 22 11 7 -7 6 -17 -5 -32z m-502 2 c1 -9 9
-32 18 -49 10 -18 15 -33 13 -33 -3 0 5 -11 17 -23 12 -13 27 -23 32 -23 6 1
10 -5 10 -13 0 -29 -21 -19 -58 30 -22 27 -37 56 -34 63 3 8 3 17 0 20 -4 3
-8 18 -9 32 -2 14 0 23 3 20 4 -3 7 -14 8 -24z m553 -27 c-6 -13 -15 -22 -19
-19 -9 5 11 44 23 44 4 0 3 -11 -4 -25z m62 -14 c0 -22 -13 -39 -26 -34 -10 4
-11 10 -3 24 10 20 29 26 29 10z m-208 -48 c-60 -74 -300 -305 -306 -295 -8
13 302 332 322 332 9 0 3 -14 -16 -37z m258 6 c-16 -28 -32 -24 -18 6 6 13 15
22 19 19 5 -3 4 -14 -1 -25z m-202 -4 c-5 -26 -321 -336 -331 -326 -6 6 50 71
154 175 90 91 168 165 172 166 5 0 7 -7 5 -15z m1721 -114 c-6 -18 -112 -100
-121 -95 -4 3 -27 33 -49 67 l-41 62 52 46 52 46 55 -58 c30 -32 53 -63 52
-68z m330 77 l32 -28 -22 -30 c-13 -16 -26 -30 -30 -30 -4 0 -26 18 -49 39
l-42 39 21 31 c25 34 24 34 90 -21z m-1786 22 c-6 -22 -21 -35 -29 -27 -7 7
15 47 25 47 5 0 7 -9 4 -20z m57 -14 c0 -8 -4 -17 -9 -21 -6 -3 -9 4 -8 15 1
23 17 28 17 6z m220 -21 c97 -13 90 -5 90 -105 l0 -89 -27 4 c-16 2 -65 9
-111 16 -50 7 -86 17 -89 25 -2 7 1 39 7 71 19 97 18 96 53 89 18 -3 52 -8 77
-11z m-416 -54 c3 -5 17 -12 32 -15 14 -4 42 -17 62 -30 20 -12 42 -21 48 -19
22 7 32 -45 28 -148 -6 -176 -10 -195 -46 -186 -19 5 -100 -29 -106 -45 -6
-12 -51 -9 -66 4 -7 7 -11 20 -8 30 3 13 -1 18 -15 18 -11 0 -38 13 -60 29
-36 26 -43 28 -59 16 -11 -8 -22 -15 -25 -15 -3 0 -11 25 -18 56 -7 30 -19 58
-27 61 -17 6 -19 49 -1 56 11 4 39 82 41 117 1 8 12 17 26 20 22 5 53 18 125
50 26 12 62 12 69 1z m1799 -49 l37 -38 -30 -29 -31 -29 -39 44 -39 45 27 22
c15 13 29 23 32 23 3 -1 22 -18 43 -38z m-438 -39 c25 -37 45 -72 45 -78 -1
-10 -101 -75 -117 -75 -12 0 -84 151 -77 162 4 6 90 55 101 58 2 0 23 -30 48
-67z m-194 -109 c22 -48 38 -88 36 -90 -13 -13 -121 -53 -127 -47 -11 12 -61
166 -55 171 3 4 95 49 103 51 2 1 21 -38 43 -85z m445 -1 c7 -10 14 -21 14
-24 0 -10 -232 -146 -306 -180 -52 -23 -69 -27 -76 -17 -12 21 -11 23 40 47
59 29 237 136 277 167 36 28 35 28 51 7z m-649 -67 c13 -41 26 -81 29 -89 4
-12 -7 -20 -47 -31 -84 -25 -84 -25 -97 57 -7 39 -15 81 -18 93 -5 18 1 22 38
31 73 18 71 19 95 -61z m-569 16 c75 -11 112 -21 112 -29 0 -9 -15 -13 -45
-13 -51 0 -206 29 -219 40 -13 13 4 32 23 25 9 -4 67 -14 129 -23z m361 -29
c6 -27 13 -69 17 -95 l6 -47 -33 -5 c-102 -16 -99 -17 -100 28 -1 23 -2 66 -3
96 l-1 55 35 6 c67 12 67 12 79 -38z m-444 -37 c39 -8 98 -18 133 -22 42 -4
62 -11 62 -20 0 -17 -23 -17 -130 0 -124 20 -170 34 -170 52 0 10 6 13 18 10
9 -3 49 -12 87 -20z"/>
        <path d="M2115 4170 c-80 -32 -60 -140 25 -140 29 0 43 6 54 22 43 61 -14 145
-79 118z"/>
        <path d="M1496 3859 c-30 -36 -31 -40 -11 -79 15 -29 53 -47 85 -41 30 6 60
44 60 76 0 72 -87 101 -134 44z m92 -11 c26 -26 1 -78 -38 -78 -43 0 -62 59
-26 82 17 12 51 9 64 -4z"/>
        <path d="M1533 3824 c-7 -18 11 -39 26 -30 17 11 13 46 -4 46 -9 0 -18 -7 -22
-16z"/>
        <path d="M1856 3904 c-10 -26 19 -52 42 -38 9 6 17 20 17 30 0 26 -50 32 -59
8z"/>
        <path d="M3609 3863 c-88 -54 -74 -192 24 -230 52 -21 102 -10 144 32 28 28
33 40 33 82 -1 57 -16 87 -56 114 -36 23 -109 25 -145 2z m111 -28 c60 -31 70
-107 19 -149 -44 -37 -90 -35 -123 5 -39 46 -32 101 18 141 26 22 49 22 86 3z"/>
        <path d="M3650 3790 c-11 -11 -20 -29 -20 -39 0 -24 26 -51 49 -51 39 0 67 65
39 87 -28 21 -49 22 -68 3z"/>
        <path d="M1182 3531 c-21 -13 -10 -46 16 -49 27 -4 40 22 22 43 -14 17 -20 18
-38 6z"/>
        <path d="M1082 3148 c-7 -7 -12 -25 -12 -40 0 -38 43 -57 71 -32 35 32 18 84
-26 84 -12 0 -26 -5 -33 -12z"/>
        <path d="M1280 2990 c-24 -24 -24 -29 -5 -57 32 -46 85 -29 85 27 0 47 -46 64
-80 30z"/>
        <path d="M858 2982 c-64 -58 -24 -162 63 -162 28 0 89 62 89 89 0 12 -7 35
-15 51 -29 57 -87 66 -137 22z m101 -28 c12 -15 21 -32 21 -39 0 -24 -41 -65
-65 -65 -31 0 -67 46 -59 74 7 27 41 56 64 56 10 0 28 -12 39 -26z"/>
        <path d="M880 2940 c-16 -29 -3 -59 27 -66 41 -11 71 44 41 74 -19 19 -56 14
-68 -8z m55 -10 c8 -13 -14 -42 -26 -34 -5 3 -9 14 -9 25 0 20 24 26 35 9z"/>
        <path d="M975 2600 c-35 -11 -72 -56 -86 -103 -20 -70 39 -158 115 -173 51 -9
124 28 142 72 28 67 9 155 -41 188 -31 20 -92 28 -130 16z m100 -86 c32 -34
32 -68 0 -99 -30 -30 -64 -32 -99 -4 -34 27 -35 71 -1 104 32 33 70 32 100 -1z"/>
        <path d="M1496 2665 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"/>
        <path d="M4320 2555 c-17 -20 -5 -45 20 -45 11 0 23 7 26 15 6 15 -11 45 -26
45 -4 0 -13 -7 -20 -15z"/>
        <path d="M1515 2251 c-3 -5 -2 -12 3 -15 5 -3 9 1 9 9 0 17 -3 19 -12 6z"/>
        <path d="M1548 2243 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
        <path d="M1990 1540 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
        <path d="M1014 2000 c-80 -32 -86 -169 -9 -210 32 -17 92 -18 124 -1 30 16 61
69 61 104 0 85 -92 141 -176 107z m119 -51 c32 -40 21 -101 -24 -124 -38 -20
-62 -19 -93 6 -43 34 -33 118 17 139 32 14 79 3 100 -21z"/>
        <path d="M1048 1929 c-44 -25 -3 -93 42 -69 26 14 26 56 1 70 -23 12 -22 12
-43 -1z"/>
        <path d="M2636 1119 c3 -5 13 -9 22 -10 14 0 13 -2 -3 -10 -14 -6 -7 -7 25 -3
35 4 42 3 30 -6 -13 -10 -12 -11 3 -6 10 3 24 6 30 7 17 1 -29 18 -75 28 -22
5 -36 5 -32 0z"/>
        <path d="M1801 1494 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
        <path d="M2130 1529 c0 -5 9 -9 20 -9 11 0 20 2 20 4 0 2 -9 6 -20 9 -11 3
-20 1 -20 -4z"/>
        <path d="M2198 1463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
        <path d="M1541 1444 c-41 -34 -42 -80 -5 -118 34 -34 66 -33 106 2 41 38 43
85 4 119 -35 30 -67 29 -105 -3z m67 -62 c-2 -8 -8 -17 -14 -19 -13 -4 -27 24
-19 37 9 14 36 -1 33 -18z"/>
        <path d="M1910 1118 c-30 -15 -53 -37 -67 -63 -93 -166 110 -326 249 -197 59
56 68 146 21 211 -23 31 -97 71 -133 71 -14 0 -46 -10 -70 -22z m114 -104 c37
-36 6 -104 -48 -104 -48 0 -73 64 -39 102 20 22 66 24 87 2z"/>
        <path d="M1677 3127 c-3 -8 -1 -20 5 -28 8 -11 9 -11 5 3 -3 9 -1 18 6 21 8 2
8 5 0 10 -6 4 -13 1 -16 -6z"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "KazangBanner",
};
</script>

<style lang="scss" scoped>
.kazang-logo {
  background: #24445c;
  width: 100%;
  max-height: 100%;
  height: 100%;

  svg {
    height: 100%;
    width: 100%;
  }
}
</style>
