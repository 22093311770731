<template>
  <div id="app">
    <Navbar />
    <router-view/>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
name: "Layout",
  components: {Navbar}
}
</script>

<style scoped>

</style>