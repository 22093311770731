export function showSuccessAlert(message) {
    Swal.fire({
        position: 'top-end',
        icon: 'success',
        text: message,
        showConfirmButton: false,
        timer: 1300,
        width: 500,
        padding: '1em',
    });
}
export function showErrorAlert(message) {
    Swal.fire({
        icon: 'error',
        text: message,
    });
}

