<template>
  <div class="card mt-5 border-0 max-width">
    <div class="card-body p-1">
      <h4
        class="card-title mb-3 text-dark text-uppercase"
        style="font-weight: 700"
      >
        <span style="color: #9ccc65">User</span> Login
      </h4>
      <form @submit.prevent="login">
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">User Name</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            v-model="user.username"
          />
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Password</label>
          <input
            :type="passType"
            class="form-control"
            id="exampleInputPassword1"
            v-model="user.password"
          />
        </div>
        <div class="mb-3 form-check">
          <input
            type="checkbox"
            class="form-check-input"
            v-model="showPass"
            id="exampleCheck1"
          />
          <label class="form-check-label" for="exampleCheck1"
            >Show Password</label
          >
        </div>

        <button class="btn btn-success" type="submit" :disabled="loading">
          <div
            class="spinner-border"
            style="width: 2rem; height: 2rem"
            role="status"
            v-if="this.loadingText === 'Loading...'"
          ></div>
          <span role="status" class="ps-4">{{ this.loadingText }}</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>

import { showSuccessAlert, showErrorAlert} from '@/Helpers/swalHelpers'
export default {
  name: "userLogin",
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      loading: false,
      showPass: false,
    };
  },
  computed: {
    passType() {
      return this.showPass ? "text" : "password";
    },
    loadingText() {
      return this.loading ? "Loading..." : "Login";
    },
  },
  methods: {
    login() {
      this.loading = true;
      const user = {
        Username: this.user.username,
        password: this.user.password,
      };
      return this.$store.dispatch("authenticateUser", user).then((result)=> {
        if (result === true) {
          // Success
          showSuccessAlert('Login Successful')
        } else {
          showErrorAlert('Login Failed ! ')
        }
      } )
          .finally(() => {
        this.loading = false;
      })
    },
    showAlert() {

      this.$swal('Hello Vue world!!!');
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  max-width: 400px;
  width: 95vw;
  position: relative;
}

button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  span{
  font-size: 1.3rem;

  }
}
</style>
