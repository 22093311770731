<template>
  <div class="container">
    <h5 class="my-2 fw-bold">{{ greeting }}</h5>
    <div class="row">
      <div v-for="(setup, i) in links" :key="i" class="col-md-4 m-2 card text-center" style="width: 18rem"
           @click="openPage(setup.page, setup.isInternal)">
        <img width="250px" height="178px" :src="setup.url" class="card-img-top"/>
        <hr />

        <div class="card-body p-2">
          <h5 class="fw-bold card-title">{{ setup.title }}</h5>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  computed: {
    ...mapState(["userInfo"]),
    greeting() {
      let user = this.userInfo;
      if (!user) return ``;
      const today = new Date();
      const currentHour = today.getHours()
      const currentMin = today.getMinutes()
      if (currentHour >= 0 && (currentHour <= 11 && currentMin <= 59)) {
        return `Good Morning ${user.Username}`
      } else if (currentHour >= 12 && (currentHour <= 17 && currentMin <= 59)) {
        return `Good Afternoon ${user.Username}`
      } else {
        return `Good Evening ${user.Username}`
      }
    }
  },
  data() {
    return {
      links: [
        {
          title: "Setup Files and Commands",
          url: "https://images.freeimages.com/images/large-previews/d8b/settings-1241475.jpg",
          page: "setups",
          isInternal: true,
        },
        {
          title: "Database Files and Templates",
          url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBN55Ria-inpblh1QFXJ7s8p0c36VlPIwk92Dc6DZxYJ4APOV18XXkAI1qJQbZ020n5sk&usqp=CAU",
          page: "databases",
          isInternal: true,
        },

        {
          title: "Customer Support",
          url: "https://www.pngitem.com/pimgs/m/499-4992317_customer-support-icon-png-transparent-png.png",
          page: `https://hd.chrilantech.com/signin?userInfo=${window.btoa(localStorage.getItem(`userInfo`))}`,
          isInternal: false,
        },
        {
          title: "Chrilan Sites",
          url: "/img/icons/testp.jpeg",
          page: "ChrilanSites",
          isInternal: true,
        },
      ]
    }
  },
  methods: {
    openPage(page, isInternal = true) {
      if (isInternal) return this.$router.push(page);
      return window.open(page, `_blank`);
    },

  }

};
</script>

<style lang="scss" scoped></style>
