import Vue from "vue";
import VueRouter from "vue-router";
import UserLoginView from "@/views/UserLoginView.vue";
import DashboardView from "@/views/DashboardView.vue";
import Layout from "@/components/layout/Layout";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "userLogin",
        component: UserLoginView,
    },

    {
        path: "/",
        component: Layout,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: DashboardView,
            },
            {
                path: "/setups",
                name: "setups",
                component: () => import("../views/SetupsView.vue"),
            },
            {
                path: "/databases",
                name: "databases",
                component: () => import("../views/DatabasesView.vue"),
            },
            {
                path: "/ChrilanSites",
                name: "ChrilanSites",
                component: () => import("../views/ChrilanSites.vue"),
            },
        ]

    },


];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    let nextPage = to.name;
    if (!user && nextPage !== "userLogin") {
        next({name: "userLogin"});
    } else {
        next();
    }
});

export default router;
